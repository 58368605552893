import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import {
  UilUser,
  UilTimes,
  UilLock,
  UilWhatsapp,
  UilMoneyBillStack,
  UilAlignLeft,
} from "@iconscout/react-unicons";
import ProfileDetails from "../../components/ProfileDetails";
import ChangePassword from "../../components/ChangePassword";
import BillingPlan from "./BillingPlan";
import AccountBalance from "../../components/AccountBalance";
import { useLocation } from "react-router-dom";

type Props = {};

const Settings: React.FC = (props: Props) => {
  const { currentUser, loading } = useSelector(
    (state: RootState) => state.auth
  );
  const user = currentUser;
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("profile-details");
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleItemClick = (item: string) => {
    setActiveItem(item);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    // Extract the query parameter from the URL
    const params = new URLSearchParams(location.search);
    const item = params.get("item");

    if (item) {
      setActiveItem(item);
    }
  }, [location]);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container apps-container">
          <div className="nxl-content without-header nxl-full-content">
            <div className="main-content d-flex">
              {/* <!-- [ Content Sidebar ] start --> */}
              <div
                // className="content-sidebar content-sidebar-md"
                className={`content-sidebar content-sidebar-md ${
                  isSidebarVisible ? "app-sidebar-open" : ""
                }`}
                data-scrollbar-target="#psScrollbarInit"
              >
                <div className="content-sidebar-header bg-white sticky-top hstack justify-content-between">
                  <h4 className="fw-bolder mb-0">Settings</h4>
                  <button
                    onClick={toggleSidebar}
                    className="app-sidebar-close-trigger d-flex bg-transparent border-0"
                  >
                    <UilTimes />
                  </button>
                </div>
                <div className="content-sidebar-body">
                  <ul className="nav flex-column nxl-content-sidebar-item">
                    {loading ? (
                      <>
                        <span className="loader"></span> fetching Details
                      </>
                    ) : (
                      <>
                        <li className="nav-item">
                          <button
                            onClick={() => handleItemClick("profile-details")}
                            className={`nav-link ${
                              activeItem === "profile-details"
                                ? "active"
                                : "bg-transparent"
                            } border-0 a-div w-100`}
                          >
                            <UilUser />
                            <span>Profile Details</span>
                          </button>
                        </li>
                        {user?.role === "USER" && (
                          <>
                            <li className="nav-item">
                              <button
                                onClick={() =>
                                  handleItemClick("change-password")
                                }
                                className={`nav-link ${
                                  activeItem === "change-password"
                                    ? "active"
                                    : "bg-transparent"
                                } border-0 a-div w-100`}
                              >
                                <UilLock />
                                <span>Change Password</span>
                              </button>
                            </li>
                            <li className="nav-item">
                              <button
                                onClick={() =>
                                  handleItemClick("account-balance")
                                }
                                className={`nav-link ${
                                  activeItem === "account-balance"
                                    ? "active"
                                    : "bg-transparent"
                                } border-0 a-div w-100`}
                              >
                                <UilMoneyBillStack />
                                <span>Account Balance</span>
                              </button>
                            </li>
                            <li className="nav-item">
                              <button
                                onClick={() => handleItemClick("billing-plans")}
                                className={`nav-link ${
                                  activeItem === "billing-plans"
                                    ? "active"
                                    : "bg-transparent"
                                } border-0 a-div w-100`}
                              >
                                <UilWhatsapp />
                                <span>Whatsapp Subscription</span>
                              </button>
                            </li>
                          </>
                        )}
                      </>
                    )}
                  </ul>
                </div>
              </div>
              {/* <!-- [ Content Sidebar ] end --> */}

              {/* Render component based on activeItem */}
              <div className="main-content-area w-100">
                <div className="d-lg-none d-flex cursor-pointer">
                  <div
                    className="menu-toggle text-black"
                    onClick={toggleSidebar}
                  >
                    <UilAlignLeft />
                  </div>
                </div>
                {activeItem === "profile-details" && (
                  <ProfileDetails user={user} />
                )}
                {activeItem === "change-password" && <ChangePassword />}
                {activeItem === "account-balance" && <AccountBalance />}
                {activeItem === "billing-plans" && <BillingPlan user={user} />}
                {activeItem === "support" && <div>Support Component</div>}
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default Settings;
