import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Link,
} from "react-router-dom";
// import ReactJoyride from "react-joyride";
import { UilExclamationTriangle } from "@iconscout/react-unicons";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Dashboard from "../pages/app/Dashboard";
import ContactsCreate from "../pages/app/ContactsCreate";
import { Toaster } from "react-hot-toast";
import "../App.css";
import Settings from "../pages/app/Settings";
import ResetPassword from "../pages/app/ResetPassword";
// import TestPage from "../pages/app/TestPage";
import Navigation from "./Navigation";
import { AuthProvider } from "./auth/AuthContext";
import AddPricing from "../pages/app/AddPricing";
import PricingPlans from "../pages/app/PricingPlans";
import PageNotFound from "../pages/app/PageNotFound";
import AllAdmins from "../pages/app/AllAdmins";
import BulkSMS from "../pages/app/BulkSMS";
import BulkToGroup from "../pages/app/BulkToGroup";
import ClientCreate from "../pages/app/ClientCreate";
import ClientDetails from "../pages/app/ClientDetails";
import ClientList from "../pages/app/ClientList";
import Contacts from "../pages/app/Contacts";
import ContactsGroup from "../pages/app/ContactsGroup";
import Coverage from "../pages/app/Coverage";
import ForgotPassword from "../pages/app/ForgotPassword";
import Login from "../pages/app/Login";
import ManageRoles from "../pages/app/ManageRoles";
import OtpConfirmation from "../pages/app/OtpConfirmation";
import PaymentList from "../pages/app/PaymentList";
import PricingList from "../pages/app/PricingList";
import Pricings from "../pages/app/Pricings";
import Register from "../pages/app/Register";
import SingleSMS from "../pages/app/SingleSMS";
import SmsHistoric from "../pages/app/SmsHistoric";
import SubscribePackage from "../pages/app/SubscribePackage";
import UnAuthorized from "../pages/app/UnAuthorized";
import UpdateClientBalance from "../pages/app/UpdateClientBalance";
import HomePage from "../pages/public/HomePage";
import WhatsappPage from "../pages/app/WhatsappPage";
import { socket } from "../utils/socket";
import TermsAndConditions from "../pages/public/TermsAndConditions";
import PrivacyPolicy from "../pages/public/PrivacyPolicy";
import { logMessage } from "../utils/logMessage";
import WhatsappHistory from "../pages/app/WhatsappHistory";
import ApiKeysPage from "../pages/app/ApiKeysPage";
import ManageIpAddresses from "../pages/app/ManageIpAddresses";
import FrequentlyAskedQuestions from "../pages/public/FrequentlyAskedQuestions";
import {
  homepageTourSteps,
  dashboardTourSteps,
} from "../data/JoyrideTourSteps";
import { SOCKET_EVENTS } from "../utils/socketEvents";
import ProtectedRoute from "./auth/ProtectedRoute";

interface MainProps {
  isMiniMenu: boolean;
  onToggleMenu: () => void;
}

const App: React.FC = () => {
  const [isMiniMenu, setIsMiniMenu] = useState(false);
  const [showBalanceModal, setShowBalanceModal] = useState(false);
  const [tourSteps, setTourSteps] = useState<
    { target: string; content: string }[]
  >([]);

  const handleMenuToggle = () => {
    setIsMiniMenu(!isMiniMenu);
  };

  useEffect(() => {
    // Connect the socket only when the App component mounts
    socket.connect();

    // Listen for connection events
    socket.on("connect", () => logMessage("Connected to WebSocket server"));
    socket.on("disconnect", () =>
      logMessage("Disconnected from WebSocket server")
    );

    socket.on(SOCKET_EVENTS.INSUFFICIENT_BALANCE, () =>
      setShowBalanceModal(true)
    );
    // Optionally: clean up on unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Router>
      <AuthProvider>
        <RouteChangeHandler setTourSteps={setTourSteps} />
        {/* <ReactJoyride
          steps={tourSteps}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
        /> */}
        <Main isMiniMenu={isMiniMenu} onToggleMenu={handleMenuToggle} />
        {showBalanceModal && (
          <div
            className={`modal fade ${showBalanceModal ? "show" : ""}`}
            tabIndex={-1}
            role="dialog"
            aria-hidden={!showBalanceModal}
            style={{
              display: showBalanceModal ? "block" : "none",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="d-flex gap-1 align-items-center">
                    <UilExclamationTriangle className="text-warning" />
                    <h5 className="modal-title">Insufficient Balance</h5>
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowBalanceModal(false)}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  Your balance is not sufficient to perform this operation.
                </div>
                <div className="modal-footer">
                  <Link
                    to={`settings?item=account-balance`}
                    className="btn btn-primary m-0"
                    onClick={() => setShowBalanceModal(false)} // Close modal after navigation
                  >
                    Top Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </AuthProvider>
    </Router>
  );
};

// Separate component for handling route changes
const RouteChangeHandler: React.FC<{
  setTourSteps: React.Dispatch<
    React.SetStateAction<{ target: string; content: string }[]>
  >;
}> = ({ setTourSteps }) => {
  const location = useLocation();

  useEffect(() => {
    // Determine which tour to show based on the current route
    if (location.pathname === "/") {
      setTourSteps(homepageTourSteps); // Use steps for homepage
    } else if (location.pathname === "/dashboard") {
      setTourSteps(dashboardTourSteps); // Use steps for dashboard
    }
  }, [location.pathname, setTourSteps]);

  return null;
};

const Main: React.FC<MainProps> = ({ isMiniMenu, onToggleMenu }) => {
  const location = useLocation();

  const hideNavPaths = [
    "/",
    "/terms-and-conditions",
    "/privacy-policy",
    "/auth",
    "/auth/verification",
    "/register",
    "/auth/reset-password",
    "/auth/forgot-password",
    "/unauthorized",
    "/test",
    "/faq",
  ];

  const definedPaths = [
    "/dashboard",
    "/payment-list",
    "/client-list",
    "/client-create",
    "/client-details",
    "/coverage",
    "/manage-pricing",
    "/add-pricing",
    "/packages-whatsapp",
    "/admin",
    "/manage-roles",
    "/contacts",
    "/direct-sms",
    "/send-bulk",
    "/sms-historic",
    "/settings",
    "/whatsapp",
    "/subscribe",
    "/all-pricing",
    // "/test",
    "/apis",
    "/manage-ip-addresses",
  ];

  const isMatchingDefinedPath = definedPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  const is404Page =
    !isMatchingDefinedPath && !hideNavPaths.includes(location.pathname);

  const shouldShowNav = !hideNavPaths.includes(location.pathname) && !is404Page;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className={`app ${isMiniMenu ? "mini-menu" : ""}`}>
        {shouldShowNav && (
          <Navigation isMiniMenu={isMiniMenu} onToggleMenu={onToggleMenu} />
        )}
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<HomePage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/faq" element={<FrequentlyAskedQuestions />} />

          {/* Protected routes */}
          <Route
            path="/payment-list"
            element={
              <ProtectedRoute allowedRoles={["ADMIN", "SUPER_ADMIN"]}>
                <PaymentList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client-list"
            element={
              <ProtectedRoute allowedRoles={["ADMIN", "SUPER_ADMIN"]}>
                <ClientList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client-create"
            element={
              <ProtectedRoute allowedRoles={["ADMIN", "SUPER_ADMIN"]}>
                <ClientCreate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client-details/:userCode"
            element={
              <ProtectedRoute allowedRoles={["ADMIN", "SUPER_ADMIN"]}>
                <ClientDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/coverage"
            element={
              <ProtectedRoute allowedRoles={["SUPER_ADMIN"]}>
                <Coverage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-pricing"
            element={
              <ProtectedRoute allowedRoles={["SUPER_ADMIN"]}>
                <PricingList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-pricing"
            element={
              <ProtectedRoute allowedRoles={["SUPER_ADMIN"]}>
                <AddPricing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/packages-whatsapp"
            element={
              <ProtectedRoute allowedRoles={["ADMIN", "SUPER_ADMIN"]}>
                <PricingPlans />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute allowedRoles={["ADMIN", "SUPER_ADMIN"]}>
                <AllAdmins />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-ip-addresses"
            element={
              <ProtectedRoute allowedRoles={["ADMIN", "SUPER_ADMIN"]}>
                <ManageIpAddresses />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client-details/:id/balance"
            element={
              <ProtectedRoute allowedRoles={["SUPER_ADMIN"]}>
                <UpdateClientBalance />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-roles"
            element={
              <ProtectedRoute allowedRoles={["SUPER_ADMIN"]}>
                <ManageRoles />
              </ProtectedRoute>
            }
          />

          {/* Auth routes */}
          <Route path="/auth" element={<Login />} />
          <Route path="/auth/verification" element={<OtpConfirmation />} />
          <Route path="/register" element={<Register />} />
          <Route path="/auth/reset-password" element={<ResetPassword />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />

          {/* Other routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute allowedRoles={["USER"]}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contacts"
            element={
              <ProtectedRoute allowedRoles={["USER"]}>
                <Contacts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contacts-create"
            element={
              <ProtectedRoute allowedRoles={["USER"]}>
                <ContactsCreate />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contacts-group"
            element={
              <ProtectedRoute allowedRoles={["USER"]}>
                <ContactsGroup />
              </ProtectedRoute>
            }
          />
          <Route
            path="/direct-sms"
            element={
              <ProtectedRoute allowedRoles={["USER"]}>
                <SingleSMS />
              </ProtectedRoute>
            }
          />
          <Route
            path="/send-bulk"
            element={
              <ProtectedRoute allowedRoles={["USER"]}>
                <BulkSMS />
              </ProtectedRoute>
            }
          />
          <Route
            path="/send-bulk-group"
            element={
              <ProtectedRoute allowedRoles={["USER"]}>
                <BulkToGroup />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sms-historic"
            element={
              <ProtectedRoute allowedRoles={["USER"]}>
                <SmsHistoric />
              </ProtectedRoute>
            }
          />
          <Route
            path="/whatsapp-history"
            element={
              <ProtectedRoute allowedRoles={["USER"]}>
                <WhatsappHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              // <ProtectedRoute allowedRoles={["USER"]}>
              <Settings />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/whatsapp"
            element={
              <ProtectedRoute allowedRoles={["USER"]}>
                <WhatsappPage socket={socket} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/subscribe"
            element={
              <ProtectedRoute allowedRoles={["USER"]}>
                <SubscribePackage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/all-pricing"
            element={
              <ProtectedRoute allowedRoles={["USER"]}>
                <Pricings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/apis"
            element={
              <ProtectedRoute allowedRoles={["USER"]}>
                <ApiKeysPage socket={socket} />
              </ProtectedRoute>
            }
          />

          {/* Unauthorized page */}
          <Route path="/unauthorized" element={<UnAuthorized />} />

          {/* 404 Not Found Route */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Toaster />
      </div>
    </LocalizationProvider>
  );
};
export default App;
