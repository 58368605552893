import {
  UilAirplay,
  UilDollarSignAlt,
  UilUsersAlt,
  UilSetting,
  UilComment,
  UilCoins,
  UilWhatsapp,
  UilLightbulbAlt,
  UilMoneyBill,
  UilMonitor,
} from "@iconscout/react-unicons";

type MenuItem = {
  label: string;
  icon: JSX.Element;
  path: string;
  subMenu?: {
    label: string;
    path: string;
  }[];
};

export const menuItems: MenuItem[] = [
  {
    label: "Dashboards",
    icon: <UilAirplay />,
    path: "/dashboard",
  },
  {
    label: "Pricings",
    icon: <UilMoneyBill />,
    path: "/all-pricing",
    subMenu: [
      // { label: "GSM Pricings", path: "all-pricing" },
      { label: "WhatsApp Packages", path: "subscribe" },
    ],
  },
  // {
  //   label: "Payment",
  //   icon: <UilDollarSignAlt />,
  //   path: "/payment",
  //   subMenu: [
  //     { label: "Payment List", path: "payment-list" },
  //     { label: "Billing", path: "invoice-create.html" },
  //   ],
  // },
  {
    label: "Messages",
    icon: <UilComment />,
    path: "/direct-sms",
    subMenu: [
      { label: "Direct SMS", path: "direct-sms" },
      { label: "Bulk SMS From File", path: "send-bulk" },
      { label: "Bulk SMS From Group", path: "send-bulk-group" },
      { label: "Whatsapp", path: "whatsapp" },
      { label: "Messages History", path: "sms-historic" },
      { label: "WhatsApp History", path: "whatsapp-history" },
      // { label: "Create a Campaign", path: "invoice-create.html" },
      // { label: "SMS Template", path: "invoice-create.html" },
    ],
  },
  {
    label: "Contacts",
    icon: <UilUsersAlt />,
    path: "/contacts",
    subMenu: [
      { label: "Contact List", path: "contacts" },
      { label: "Contacts Create", path: "contacts-create" },
      { label: "Contacts Groups", path: "contacts-group" },
    ],
  },
  {
    label: "Settings",
    icon: <UilSetting />,
    path: "/settings",
    subMenu: [
      { label: "Account", path: "settings" },
      // { label: "SEO", path: "/q" },
      // { label: "Tags", path: "/q" },
    ],
  },
  {
    label: "Developers",
    icon: <UilMonitor />,
    path: "/developers",
    subMenu: [
      { label: "API Keys", path: "apis" },
      {
        label: "Developer Docs",
        path: "https://docs.digitwace.com/",
      },
    ],
  },
  {
    label: "Clients",
    icon: <UilUsersAlt />,
    path: "/clients",
    subMenu: [
      { label: "Client List", path: "client-list" },
      { label: "Create Client", path: "client-create" },
      { label: "Manage IP Adresses", path: "manage-ip-addresses" },
    ],
  },
  // {
  //   label: "Whatsapp Message",
  //   icon: <UilWhatsapp />,
  //   path: "/whatsapp-message",
  //   subMenu: [
  //     { label: "Send a Message", path: "whatsapp-message" },
  //     { label: "Extract Contacts", path: "extract-contacts" },
  //   ],
  // },
  {
    label: "Manage Pricing",
    icon: <UilCoins />,
    path: "/manage-pricing",
    subMenu: [
      { label: "All Pricing", path: "/manage-pricing" },
      { label: "Add Pricing", path: "/add-pricing" },
      { label: "Add Country", path: "/coverage" },
      { label: "Whatsapp Packages", path: "/packages-whatsapp" },
    ],
  },
  {
    label: "Administrator",
    icon: <UilLightbulbAlt />,
    path: "/admin",
    subMenu: [
      { label: "All Administrators", path: "/admin" },
      { label: "Manage roles", path: "/manage-roles" },
      // { label: "Tags", path: "/q" },
    ],
  },
  // {
  //   label: "Help Center",
  //   icon: <UilLifeRing />,
  //   path: "/help-center",
  //   subMenu: [
  //     { label: "Support", path: "https://wrapcoders.tawk.help/" },
  //     { label: "KnowledgeBase", path: "help-knowledgebase.html" },
  //     {
  //       label: "Documentations",
  //       path: "https://getnexel.netlify.app/docs/documentations.html",
  //     },
  //   ],
  // },
];
