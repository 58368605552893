import React from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";

type Props = {};

const UnAuthorized = (props: Props) => {
  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="">
          <div className="row">
            <div className="col-md-6 mx-auto">
              <div className="card mb-4 mt-5 mx-4 mx-sm-0 position-relative">
                <div className="card-body p-sm-5 text-center">
                  <h2 className="fw-bolder mb-4" style={{ fontSize: "120px" }}>
                    4<span className="text-danger">0</span>1
                  </h2>
                  <h4 className="fw-bold mb-2">
                    You Don't Have Access to this Page
                  </h4>
                  <p className="fs-12 fw-medium text-muted">
                    Sorry, you are not authorized to view this page. Please log
                    in with appropriate credentials or contact the administrator
                    for access.
                  </p>
                  <div className="mt-5">
                    <a className="btn btn-light-brand w-100" href="/">
                      Back Home
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default UnAuthorized;
