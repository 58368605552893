export const logoData = [
  {
    img: "../assets/images/logo/digitwace.png",
    title: "DigitWace",
  },
  {
    img: "../assets/images/logo/wt.png",
    title: "WT",
  },
  {
    img: "../assets/images/logo/digitStore.png",
    title: "DigitStore",
  },
  {
    img: "../assets/images/logo/tsc.png",
    title: "TSC Pay",
  },
  {
    img: "../assets/images/logo/wacepay.png",
    title: "Wacepay",
  },
  {
    img: "../assets/images/logo/agensic.png",
    title: "Agensic",
  },
  {
    img: "../assets/images/logo/digittrade.png",
    title: "DigitTrade",
  },
];
