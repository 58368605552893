import axiosInstance from "./axiosInstance";

const getRecharges = async () => {
  const response = await axiosInstance.get(`recharges/user/all`);
  return response.data;
};
const getBalance = async () => {
  const response = await axiosInstance.get(`account`);
  return response.data;
};

const topUpBalance = async (data: { amount: number }) => {
  const response = await axiosInstance.post("recharges", data);
  return response.data;
};

const createPaymentIntent = async (data: {
  amount: number;
  currency: string;
}) => {
  const response = await axiosInstance.post(
    "recharges/create-payment-intent",
    data
  );
  return response.data;
};

const confirmPayment = async (data: any) => {
  const response = await axiosInstance.post("recharges/payment-success", data);
  return response.data;
};

const retrievePaymentIntent = async (paymentIntentId: string) => {
  const response = await axiosInstance.get(
    `recharges/retrieve-payment/${paymentIntentId}`
  );
  return response.data;
};

const flutterWaveInitPayment = async (data: any) => {
  const response = await axiosInstance.post(
    "recharges/flutterwave/initiate-payment",
    data
  );
  return response.data;
};
const flutterWavePaymentCallback = async (data: any) => {
  const response = await axiosInstance.post("recharges/payment-callback", data);
  return response.data;
};

export const accountService = {
  topUpBalance,
  getBalance,
  createPaymentIntent,
  confirmPayment,
  getRecharges,
  retrievePaymentIntent,
  flutterWaveInitPayment,
  flutterWavePaymentCallback,
};
