import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactGroup, CreateGroup } from "../../models/Contacts";
import { contactService } from "../../services/contactService";

export interface ContactGroupState {
  contactGroups: ContactGroup[] | null;
  error: string | null;
}

const initialState: ContactGroupState = {
  contactGroups: null,
  error: null,
};

export const addContactGroup = createAsyncThunk<any, CreateGroup>(
  "contactGroup/create",
  async (group: CreateGroup, { dispatch }) => {
    await contactService.createContactGroup(group);
    dispatch(getAllContactGroups());
  }
);

export const getAllContactGroups = createAsyncThunk<ContactGroup[]>(
  "contactGroup/get",
  async () => {
    const response = await contactService.getAllContactGroups();
    return response.data;
  }
);

export const contactGroupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addContactGroup.pending, (state) => {
        state.error = null;
      })
      .addCase(
        addContactGroup.fulfilled,
        (state, action: PayloadAction<ContactGroup>) => {
          state.error = null;
        }
      )
      .addCase(
        getAllContactGroups.fulfilled,
        (state, action: PayloadAction<ContactGroup[]>) => {
          state.contactGroups = action.payload;
          state.error = null;
        }
      )
      .addCase(getAllContactGroups.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch contacts";
      });
  },
});

export default contactGroupSlice.reducer;
