import * as XLSX from "xlsx";
import Papa from "papaparse";
import { Contact } from "../models/Contacts";

export const parseExcelFile = async (file: File): Promise<Contact[]> => {
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data, { type: "array" });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const jsonData = XLSX.utils.sheet_to_json<Contact>(worksheet);

  // Filter out contacts without a valid phone attribute
  const validContacts = jsonData.filter((contact) => {
    const phone = contact.phone?.toString().trim();
    return phone && phone !== "";
  });

  return validContacts;
};

export const parseCSVFile = (file: File): Promise<Contact[]> => {
  return new Promise((resolve, reject) => {
    Papa.parse<Contact>(file, {
      header: true,
      complete: (results) => {
        // Filter out contacts without a valid phone attribute
        const validContacts = results.data.filter((contact) => {
          const phone = contact.phone?.toString().trim();
          return phone && phone !== "";
        });
        resolve(validContacts);
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};

export const parseTextFile = async (file: File): Promise<Contact[]> => {
  const text = await file.text();
  const lines = text.split("\n");

  // Manually define headers
  const headers = ["firstname", "lastname", "email", "phone"];

  const jsonData = lines.map((line) => {
    const values = line.split(",");
    return headers.reduce((obj, header, index) => {
      if (values[index] !== undefined) {
        (obj as any)[header.trim()] = values[index].trim();
      } else {
        (obj as any)[header.trim()] = ""; // Assign an empty string if the value is missing
      }
      return obj;
    }, {} as Contact);
  });

  // Filter out contacts without a valid phone attribute
  const validContacts = jsonData.filter((contact) => {
    const phone = contact.phone?.toString().trim();
    return phone && phone !== "";
  });

  return validContacts;
};
