import {
  UilSmile,
  UilDollarSignAlt,
  UilUsersAlt,
  UilSetting,
  UilWhatsapp,
  UilComment,
  UilLightbulbAlt,
  UilMonitor,
} from "@iconscout/react-unicons";

type FaqItem = {
  question: string;
  icon: JSX.Element;
  answer: string;
};

export const faqItems: FaqItem[] = [
  {
    question: "Is there a free trial available?",
    answer:
      "Yes, we offer a free trial that includes 10 free messages for you to explore our platform and its features before committing to a subscription.",
    icon: <UilSmile />,
  },
  {
    question: "How much does the service cost?",
    answer:
      "Our pricing is flexible and based on the number of messages you send. We offer competitive rates for both local and international messages.",
    icon: <UilDollarSignAlt />,
  },
  {
    question: "Can I send messages to multiple contacts at once?",
    answer:
      "Yes, you can send bulk messages to multiple contacts simultaneously using our platform's bulk messaging feature.",
    icon: <UilUsersAlt />,
  },
  {
    question: "How do I set up my account?",
    answer:
      "Setting up an account is simple! Just register with your phone number, verify your account, and you're ready to start sending messages.",
    icon: <UilSetting />,
  },
  {
    question: "Can I send messages via WhatsApp?",
    answer:
      "Yes, our platform allows you to send messages via both SMS and WhatsApp. You'll need to connect your WhatsApp account to our platform first.",
    icon: <UilWhatsapp />,
  },
  {
    question: "What kind of customer support is available?",
    answer:
      "We offer 24/7 customer support via email, chat, and phone. Our friendly team is here to help you with any issues or questions.",
    icon: <UilComment />,
  },
  {
    question: "Do you have any tips for message formatting?",
    answer:
      "Absolutely! You can format your messages with bold, italics, and even include emojis to make them more engaging.",
    icon: <UilLightbulbAlt />,
  },
  {
    question: "Can I track the status of my messages?",
    answer:
      "Yes, our platform provides detailed analytics and delivery reports so you can track the status and performance of your messages in real-time.",
    icon: <UilMonitor />,
  },
];
