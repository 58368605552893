import { Contact, ContactModel, CreateGroup } from "../models/Contacts";
import axiosInstance from "./axiosInstance";

const createSingleContact = async (data: Contact) => {
  const response = await axiosInstance.post("contact/create", data);
  return response.data;
};

const addContacts = async (data: Contact[]) => {
  const response = await axiosInstance.post("contact/upload", data);
  return response.data;
};

const getAllContacts = async () => {
  const response = await axiosInstance.get("contact");
  return response.data;
};

const createContactGroup = async (data: CreateGroup) => {
  const response = await axiosInstance.post("contact/group/create", data);
  return response.data;
};

const getAllContactGroups = async () => {
  const response = await axiosInstance.get("contact/group/all");
  return response.data;
};

const addContactsToGroup = async (contacts: Contact[], groupCode: string) => {
  const response = await axiosInstance.post(
    `contact/group/add/${groupCode}`,
    contacts
  );
  return response.data;
};

const editContact = async (contacts: ContactModel, contactCode: string) => {
  const response = await axiosInstance.post(
    `contact/update/${contactCode}`,
    contacts
  );
  return response.data;
};

const deleteContact = async (contactCode: string) => {
  const response = await axiosInstance.delete(`contact/delete/${contactCode}`);
  return response.data;
};

const getAllContactNotInGroups = async (groupCode: string) => {
  const response = await axiosInstance.get(`contact/not-in-group/${groupCode}`);
  return response.data;
};

const getAllWhatsappContacts = async () => {
  const response = await axiosInstance.get("contact/whatsapp");
  return response.data;
};
export const contactService = {
  createSingleContact,
  addContacts,
  getAllContacts,
  createContactGroup,
  getAllContactGroups,
  addContactsToGroup,
  editContact,
  deleteContact,
  getAllContactNotInGroups,
  getAllWhatsappContacts,
};
