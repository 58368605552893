import { useState, useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { whatsappService } from "../../services/whatsappService";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";

type Props = {
  phoneNumber: string | undefined;
  packageId: number | undefined;
  remainingExtractions: number | undefined;
  userHasBasic: boolean;
};

// Options for the number of groups to fetch
const selectOptions = [
  // { value: null, label: "All" },
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 40, label: "40" },
  { value: 50, label: "50" },
  { value: 60, label: "60" },
  { value: 70, label: "70" },
  { value: 80, label: "80" },
  { value: 90, label: "90" },
  { value: 100, label: "100" },
];

const ExtractContacts: React.FC<Props> = ({
  phoneNumber,
  packageId,
  remainingExtractions,
  userHasBasic,
}) => {
  const [extractedGroups, setExtractedGroups] = useState<{
    totalGroups: number | undefined;
    groups: any[];
  }>({ totalGroups: undefined, groups: [] });
  const [isExtracting, setIsExtracting] = useState(false);
  const [pageSize, setPageSize] = useState<number | null>(
    selectOptions[0].value
  );
  const [selectedGroups, setSelectedGroups] = useState<Set<string>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  // Format phone number by removing the '+' sign
  const formattedPhoneNumber = phoneNumber?.startsWith("+")
    ? phoneNumber.slice(1)
    : phoneNumber;

  // Fetch extracted groups
  const getExtractedGroups = useCallback(async () => {
    if (!phoneNumber || !formattedPhoneNumber) {
      toast.error("You need to enter a valid phone number.");
      return;
    }
    if (!packageId) {
      return;
    }
    setIsExtracting(true);
    try {
      const res = await whatsappService.extractWhatsappGroups(
        formattedPhoneNumber,
        packageId,
        pageSize !== null ? { page: 1, pageSize } : undefined
      );
      if (res.status === -1) {
        toast.error(res.message);
        return;
      }
      setExtractedGroups(res.data);
    } catch (error) {
      toast.error(
        "An error occurred while extracting groups. Please try again later."
      );
    } finally {
      setIsExtracting(false);
    }
  }, [phoneNumber, formattedPhoneNumber, packageId, pageSize]);

  // Handle contact extraction from selected groups
  const handleExtractContacts = async () => {
    if (selectedGroups.size === 0) {
      toast.error("No groups selected for extraction.");
      return;
    }
    setIsExtracting(true);
    try {
      const groupIds = Array.from(selectedGroups);
      const res = await whatsappService.extractContactsFromGroup({
        phoneNumber: formattedPhoneNumber!,
        groupIds,
      });
      if (res.status === -1) {
        toast.error(res.message);
        return;
      }
      toast.success(
        "Contacts extracted successfully. You'll be redirected to your contacts list",
        { duration: 4000 }
      );
      navigate("/contacts?whatsapp=true");
    } catch (error) {
      toast.error("An error occurred during contact extraction.");
    } finally {
      setIsExtracting(false);
    }
  };

  // Handle checkbox selection
  const handleCheckboxChange = (groupId: string) => {
    setSelectedGroups((prev) => {
      const updated = new Set(prev);
      if (updated.has(groupId)) {
        updated.delete(groupId);
      } else {
        updated.add(groupId);
      }
      return updated;
    });
  };

  // Handle select all checkbox
  const handleSelectAllChange = () => {
    setSelectAll((prev) => {
      const newState = !prev;
      if (newState) {
        const allGroupIds = new Set(
          extractedGroups.groups.map((group) => group.id._serialized)
        );
        setSelectedGroups(allGroupIds);
      } else {
        setSelectedGroups(new Set());
      }
      return newState;
    });
  };

  // Reset select all when groups change
  useEffect(() => {
    setSelectAll(false);
    setSelectedGroups(new Set());
  }, [extractedGroups.groups]);

  // Check if checkboxes should be disabled
  const areCheckboxesDisabled =
    selectedGroups.size >= (remainingExtractions || 0);

  const filteredGroups = extractedGroups.groups.filter((group) =>
    group.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="row">
      <div className="col-12">
        {userHasBasic && (
          <div className="alert alert-info m-0 d-flex flex-column flex-md-row align-items-center justify-content-between">
            <p className="m-0 fw-bold">
              You are currently using a free package. The package you are using
              does not permit you to extract contacts.
            </p>
            <Link to={"/subscribe"} className="btn btn-info ms-2">
              Try a better package
            </Link>
          </div>
        )}
      </div>
      <div className="col-12 d-flex justify-content-between align-items-center mb-3">
        {extractedGroups.groups.length !== 0 && (
          <h6>Select the groups you want to extract contacts from</h6>
        )}
        {selectedGroups.size > 0 && (
          <button
            className="btn btn-primary"
            onClick={handleExtractContacts}
            disabled={isExtracting || userHasBasic}
          >
            {isExtracting && <span className="loader"></span>}
            Extract Contacts from Selected Groups
          </button>
        )}
      </div>
      <div className="col-12 mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search groups by name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term
        />
      </div>
      {isExtracting && (
        <div className="col-12">
          <div className="alert alert-info mb-3">
            <p className="m-0">
              Performing extraction. This operation may take some time. Please
              be patient.
            </p>
          </div>
        </div>
      )}
      <div className="col-xxl-3 col-md-4">
        <div className="card stretch stretch-full">
          <div className="card-body">
            <div className="step-content d-flex flex-column justify-content-center align-items-center gap-3 my-2">
              <div>
                <h6 className="mb-2">Select the number of groups to fetch</h6>
                <Select
                  className="basic-single"
                  defaultValue={selectOptions[0]}
                  isLoading={isExtracting}
                  isDisabled={isExtracting || userHasBasic}
                  name="groups"
                  options={selectOptions}
                  onChange={(option) => {
                    if (option?.value === null) {
                      // Handle the case for "All" (no pagination)
                      setPageSize(null);
                    } else {
                      // Set the selected page size or fallback to default
                      setPageSize(option?.value ?? selectOptions[0].value);
                    }
                  }}
                />
                <button
                  className="btn btn-primary mt-3"
                  disabled={isExtracting || userHasBasic}
                  onClick={getExtractedGroups}
                >
                  {isExtracting && <span className="loader"></span>}
                  Fetch Groups
                </button>
              </div>
            </div>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/Vp2dXy2gpcM"
              title="Extraction Tutorial"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ height: "200px" }}
            ></iframe>
          </div>
        </div>
      </div>
      <div className="col-xxl-6 col-md-8">
        <div className="card stretch stretch-full">
          <div className="card-body">
            {isExtracting ? (
              <div className="p-3">
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" height={60} />
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="rectangular" height={60} />
                  <Skeleton variant="rectangular" height={60} />
                </Stack>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table table-hover" id="customerList">
                  <thead>
                    <tr>
                      <th className="wd-30">
                        <div className="btn-group mb-1">
                          <div className="custom-control custom-checkbox ms-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="checkAllCustomer"
                              checked={selectAll}
                              onChange={handleSelectAllChange}
                              disabled={areCheckboxesDisabled && !selectAll}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="checkAllCustomer"
                            ></label>
                          </div>
                        </div>
                      </th>
                      <th className="text-nowrap">Group Name</th>
                      <th className="text-nowrap">Number of Contacts</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredGroups.length !== 0 &&
                      filteredGroups.map((group, index) => (
                        <tr key={index} className="single-item">
                          <td>
                            <div className="item-checkbox ms-1">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input checkbox"
                                  id={group.name}
                                  checked={selectedGroups.has(
                                    group.id._serialized
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(group.id._serialized)
                                  }
                                  disabled={
                                    areCheckboxesDisabled &&
                                    !selectedGroups.has(group.id._serialized)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={group.name}
                                ></label>
                              </div>
                            </div>
                          </td>
                          <td>{group.name}</td>
                          <td>{group.contactsCount}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtractContacts;
