import moment from "moment";
import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import Stack from "@mui/material/Stack";
import {
  UilAngleLeftB,
  UilSetting,
  UilArrowLeft,
} from "@iconscout/react-unicons";
import toast from "react-hot-toast";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { Admin } from "../../models/User";
import { adminServices } from "../../services/adminService";
import { AuthService } from "../../services/authService";
import { useFormik } from "formik";

const AllAdmins: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Admin[]>([]);
  const [isEditing, setIsEditing] = useState(false); // Track if in editing mode
  const [selectedAdmin, setSelectedAdmin] = useState<Admin | null>(null);
  const [roles, setRoles] = useState<
    {
      id: number;
      code_role: "string";
      name: string;
      description: string;
      status: boolean;
    }[]
  >([]);

  const handleUpdateAdmin = async (values: any) => {
    try {
      setLoading(true);
      const user = {
        firstname: values.firstname,
        lastname: values.lastname,
        phone: values.phone,
        user_code: values.user_code,
        roleId: Number(values.role_id),
      };

      const res = await adminServices.updateAdmin(user);
      if (res.status === -1) {
        toast.error(res.message);
        return;
      }

      toast.success("Admin updated successfully.", { duration: 6000 });
      formik.resetForm();
      handleBack();
      await fetchAdmins();
    } catch (err: any) {
      const error = err.response.data.message;
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      role_id: "",
      user_code: "",
    },
    validationSchema: Yup.object().shape({
      firstname: Yup.string().required("First Name is required"),
      lastname: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      phone: Yup.string().test(
        "is-valid-phone",
        "Invalid phone number",
        function (value) {
          return value ? isValidPhoneNumber(value) : false;
        }
      ),
    }),
    onSubmit: handleUpdateAdmin,
  });

  const getAllRoles = async () => {
    try {
      await adminServices
        .getRoles()
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }

          setRoles(res.data);

          // Set the role_id dynamically after roles are fetched
          // const userRoleId = res.data.find(
          //   (role: any) => role.code_role === "USER"
          // )?.id;
          // if (userRoleId) {
          //   formik.setFieldValue("role_id", userRoleId);
          // }
        })
        .catch((err) => {
          toast.error("Failed to fetch roles");
        });
    } catch (error) {
      toast.error("Could not get roles. Please try again later");
    }
  };

  const fetchAdmins = async () => {
    try {
      setLoading(true);
      await adminServices
        .getAdminList()
        .then((res: any) => {
          if (res.status === -1) {
            return;
          }
          setUsers(res.data);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error(
        "An error occured while fetching data. Please try again later."
      );
    }
  };

  useEffect(() => {
    fetchAdmins();
    getAllRoles();
  }, []);

  const handleManage = (admin: Admin) => {
    setSelectedAdmin(admin);
    setIsEditing(true);

    formik.setValues({
      firstname: admin.firstname,
      lastname: admin.lastname,
      email: admin.email,
      user_code: admin.user_code,
      phone: admin?.phone || "",
      role_id: `${admin.role.id}`,
    });
  };

  // Handler for going back to the list
  const handleBack = () => {
    setIsEditing(false);
    setSelectedAdmin(null);
  };

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">Admin List</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>Back</span>
                    </div>
                  </div>
                  <span>export</span>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <div className="page-header-right-open-toggle">
                    <i className="feather-align-right fs-20"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card stretch stretch-full">
                    {isEditing && selectedAdmin ? (
                      // If in edit mode, show the edit form
                      <div className="">
                        <div className="card-header">
                          <h5>Edit Admin Information</h5>
                          <button
                            className="btn btn-secondary"
                            onClick={handleBack}
                          >
                            <UilArrowLeft /> Back
                          </button>
                        </div>
                        <div className="card-body row">
                          <div className="mb-3 col-md-4">
                            <label htmlFor="firstname">Firstname</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Firstname"
                              id="firstname"
                              {...formik.getFieldProps("firstname")}
                            />
                            {formik.touched.firstname &&
                            formik.errors.firstname ? (
                              <div className="text-danger">
                                {formik.errors.firstname}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="lastname">Lastname</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Lastname"
                              id="lastname"
                              {...formik.getFieldProps("lastname")}
                            />
                            {formik.touched.lastname &&
                            formik.errors.lastname ? (
                              <div className="text-danger">
                                {formik.errors.lastname}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="email">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              id="email"
                              {...formik.getFieldProps("email")}
                              readOnly
                            />
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="role_id">Role</label>
                            <select
                              className="form-control"
                              id="role_id"
                              {...formik.getFieldProps("role_id")}
                            >
                              {roles.length !== 0 &&
                                roles.map((role: any) => (
                                  <option key={role.id} value={role.id}>
                                    {role.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="phone">Phone Number</label>
                            <PhoneInput
                              placeholder="Enter phone number"
                              international
                              id="phone"
                              defaultCountry="CM"
                              className="form-control"
                              value={formik.values.phone}
                              onBlur={formik.handleBlur}
                              onChange={(phone) =>
                                formik.setFieldValue("phone", phone)
                              }
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                              <div className="text-danger">
                                {formik.errors.phone}
                              </div>
                            ) : null}
                          </div>
                          {/* Add more fields if needed */}
                          <div className="mb-3">
                            <button
                              disabled={loading || !formik.isValid}
                              onClick={() => formik.handleSubmit()}
                              className="btn btn-primary"
                            >
                              {loading && <span className="loader"></span>}
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="card-body p-0">
                        {loading ? (
                          <div className="p-3">
                            <Stack spacing={1}>
                              <Skeleton variant="rectangular" height={60} />
                              <Skeleton
                                variant="circular"
                                width={40}
                                height={40}
                              />
                              <Skeleton variant="rectangular" height={60} />
                              <Skeleton variant="rectangular" height={60} />
                            </Stack>
                          </div>
                        ) : (
                          <div className="table-responsive">
                            <table
                              className="table table-hover"
                              id="customerList"
                            >
                              <thead>
                                <tr>
                                  <th>Firstname</th>
                                  <th>Lastname</th>
                                  <th>Email</th>
                                  <th>Date Created</th>
                                  {/* <th>Credit sms</th> */}
                                  {/* <th>use api</th> */}
                                  <th>Role</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {users &&
                                  users.map((user, index) => (
                                    <tr key={index} className="single-item">
                                      <td>
                                        <span className="text-truncate-1-line">
                                          {user.firstname}
                                        </span>
                                      </td>
                                      <td>
                                        <div className="hstack gap-3 text-black">
                                          <div>
                                            <span className="text-truncate-1-line">
                                              {user.lastname}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td>{user.email}</td>
                                      <td>
                                        <div className="hstack gap-3 text-black">
                                          {moment(user.updatedAt).format(
                                            "DD/MM/YYYY HH:mm"
                                          )}
                                        </div>
                                      </td>
                                      {/* <td>{user.creditSms}</td> */}
                                      {/* <td>{user.date}</td> */}
                                      <td>
                                        <span>{user.role.name}</span>
                                      </td>
                                      <td>
                                        <div className="d-flex gap-3 align-items-center">
                                          <button
                                            className="btn btn-primary btn-light-brand py-1"
                                            onClick={() => handleManage(user)}
                                          >
                                            <UilSetting />
                                            <span>Manage</span>
                                          </button>
                                          {/* <button
                                        className="btn btn-danger btn-light-brand py-1"
                                        onClick={() => setSelectedClient(user)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteModal"
                                      >
                                        <UilTrashAlt />
                                        <span>Delete</span>
                                      </button> */}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            {users?.length === 0 && (
                              <h5 className="text-center my-5">
                                Your client list will appear here
                              </h5>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default AllAdmins;
