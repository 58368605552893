import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import PhoneInput from "react-phone-number-input";
import { UilSave } from "@iconscout/react-unicons";
import toast from "react-hot-toast";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { User } from "../../models/User";
import { AuthService } from "../../services/authService";
import Select from "react-select";
import countryList from "react-select-country-list";
import {
  RegisterValues,
  registerValidationSchema,
} from "../../validation/FormikValues";
import { adminServices } from "../../services/adminService";

type Props = {};

const ClientCreate: React.FC = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<
    {
      id: number;
      code_role: "string";
      name: string;
      description: string;
      status: boolean;
    }[]
  >([]);

  const handleCreateClient = async (values: RegisterValues) => {
    try {
      setLoading(true);
      const user: Partial<User> = {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        country:
          typeof values.country === "string"
            ? values.country
            : values.country.label,
        company: values.company,
        address: values.address,
        phone: values.phone,
        role_id: Number(values.role_id),
      };

      const res = await AuthService.registerUser(user);
      if (res.status === -1) {
        toast.error(res.message);
        return;
      }

      toast.success(
        "Client created successfully. The newly created client will receive their credentials to their email address.",
        { duration: 6000 }
      );
      formik.resetForm();
    } catch (err: any) {
      const error = err.response.data.message;
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      country: "",
      address: "",
      company: "",
      role_id: "", // role_id starts empty
    },
    validationSchema: registerValidationSchema,
    onSubmit: handleCreateClient,
  });

  const getAllRoles = async () => {
    try {
      await adminServices
        .getRoles()
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }

          setRoles(res.data);

          // Set the role_id dynamically after roles are fetched
          const userRoleId = res.data.find(
            (role: any) => role.code_role === "USER"
          )?.id;
          if (userRoleId) {
            formik.setFieldValue("role_id", userRoleId); // Update formik's role_id
          }
        })
        .catch((err) => {
          toast.error("Failed to fetch roles");
        });
    } catch (error) {
      toast.error("Could not get roles. Please try again later");
    }
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">Client Creation</h5>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card stretch stretch-full">
                    <div className="card-body">
                      <div className="col-12 mb-4">
                        <h5 className="fw-bold mb-0 me-4">
                          <span className="d-block mb-2">
                            Client Creation Form
                          </span>
                          <span className="fs-12 fw-normal text-muted text-truncate-1-line">
                            Please fill in the information carefully
                          </span>
                        </h5>
                      </div>
                      <div className="row">
                        <div className="mb-3 col-md-4">
                          <label htmlFor="firstname">Firstname</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Firstname"
                            id="firstName"
                            {...formik.getFieldProps("firstName")}
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <div className="text-danger">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3 col-md-4">
                          <label htmlFor="lastName">Lastname</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Lastname"
                            id="lastName"
                            {...formik.getFieldProps("lastName")}
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="text-danger">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3 col-md-4">
                          <label htmlFor="company">Company Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Company Name"
                            id="company"
                            {...formik.getFieldProps("company")}
                          />
                          {formik.touched.company && formik.errors.company ? (
                            <div className="text-danger">
                              {formik.errors.company}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3 col-md-4">
                          <label htmlFor="email">Email</label>
                          <input
                            className="form-control"
                            placeholder="Email"
                            id="email"
                            type="email"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3 col-md-4">
                          <label htmlFor="country">Country</label>
                          <Select
                            options={countryList().getData()} // Fetch country options
                            value={formik.values.country} // Bind the selected value to formik state
                            onChange={(option) =>
                              formik.setFieldValue("country", option)
                            } // Set the country object {label, value}
                            onBlur={formik.handleBlur}
                            name="country"
                          />
                          {formik.touched.country && formik.errors.country ? (
                            <div className="text-danger">
                              {formik.errors.country}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3 col-md-4">
                          <label htmlFor="phone">Phone Number</label>
                          <PhoneInput
                            placeholder="Enter phone number"
                            international
                            id="phone"
                            defaultCountry="CM"
                            className="form-control"
                            value={formik.values.phone}
                            onBlur={formik.handleBlur}
                            onChange={(phone) =>
                              formik.setFieldValue("phone", phone)
                            }
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-danger">
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>
                        {/* <div className="mb-3 col-md-4">
                          <label htmlFor="login">Login</label>
                          <input
                            className="form-control"
                            placeholder="Login"
                            id="login"
                            type="email"
                            {...formik.getFieldProps("login")}
                          />
                          {formik.touched.login && formik.errors.login ? (
                            <div className="text-danger">
                              {formik.errors.login}
                            </div>
                          ) : null}
                        </div> */}
                        {/* <div className="col-md-4 mb-3">
                          <label htmlFor="password">Password</label>
                          <div
                            className={`d-flex pass-container rounded ${
                              isNewPassFocused ? "focused" : ""
                            }`}
                          >
                            <input
                              type={isNewPassVisible ? "text" : "password"}
                              className="form-control border-end-0 rounded-start rounded-0"
                              placeholder="Password"
                              required
                              onFocus={handleNewPassFocus}
                              onBlur={(e: any) => {
                                handleNewPassBlur();
                                formik.handleBlur(e);
                              }}
                              onChange={formik.handleChange}
                              id="password"
                              value={formik.values.password}
                            />
                            <button
                              onClick={toggleNewPassVisibility}
                              className="bg-transparent pass rounded-0 rounded-end border-start-0 border btn"
                            >
                              {isNewPassVisible ? (
                                <UilEyeSlash />
                              ) : (
                                <UilEye className="text-primary" />
                              )}
                            </button>
                          </div>
                          {formik.touched.password && formik.errors.password ? (
                            <div className="text-danger">
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3 col-md-4">
                          <label htmlFor="confPassword">Confirm Password</label>
                          <div
                            className={`d-flex pass-container rounded ${
                              isConfPassFocused ? "focused" : ""
                            }`}
                          >
                            <input
                              type={isConfPassVisible ? "text" : "password"}
                              className="form-control border-end-0 rounded-start rounded-0"
                              placeholder="Password again"
                              required
                              onFocus={handleConfPassFocus}
                              onBlur={(e: any) => {
                                handleConfPassBlur();
                                formik.handleBlur(e);
                              }}
                              id="confPassword"
                              value={formik.values.confPassword}
                              onChange={formik.handleChange}
                            />
                            <button
                              onClick={toggleConfPassVisibility}
                              className="bg-transparent pass rounded-0 rounded-end border-start-0 border btn"
                            >
                              {isConfPassVisible ? (
                                <UilEyeSlash />
                              ) : (
                                <UilEye className="text-primary" />
                              )}
                            </button>
                          </div>
                          {formik.touched.confPassword &&
                          formik.errors.confPassword ? (
                            <div className="text-danger">
                              {formik.errors.confPassword}
                            </div>
                          ) : null}
                        </div> */}
                        <div className="mb-3 col-md-4">
                          <label htmlFor="address">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            id="address"
                            {...formik.getFieldProps("address")}
                          />
                          {formik.touched.address && formik.errors.address ? (
                            <div className="text-danger">
                              {formik.errors.address}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3 col-md-4">
                          <label htmlFor="role_id">Role</label>
                          <select
                            className="form-control"
                            id="role_id"
                            {...formik.getFieldProps("role_id")}
                          >
                            {roles.length !== 0 &&
                              roles.map((role: any) => (
                                <option key={role.id} value={role.id}>
                                  {role.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {/* <div className="mb-3 col-md-4">
                          <label htmlFor="state">State</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            id="state"
                            {...formik.getFieldProps("state")}
                          />
                          {formik.touched.state && formik.errors.state ? (
                            <div className="text-danger">
                              {formik.errors.state}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3 col-md-4">
                          <label htmlFor="city">City</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            id="city"
                            {...formik.getFieldProps("city")}
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div className="text-danger">
                              {formik.errors.city}
                            </div>
                          ) : null}
                        </div> */}
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          disabled={loading || !formik.isValid}
                          className="btn btn-primary"
                          onClick={() => formik.handleSubmit()}
                        >
                          {loading && <span className="loader"></span>}
                          <UilSave /> <span className="ms-1">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default ClientCreate;
