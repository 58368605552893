// src/components/ProtectedRoute.tsx
import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { AppDispatch, RootState } from "../../redux/store";
import { Role } from "../../data/RolesPermissions";
import { getCurrentUser } from "../../redux/slices/authSlice";

interface ProtectedRouteProps {
  allowedRoles: Array<Role>;
  children?: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  allowedRoles,
  children,
}) => {
  const { currentUser, loading } = useSelector(
    (state: RootState) => state.auth
  );

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>; // Or some other loader
  }

  // If user data hasn't been fetched yet, return null or a loader until it's done
  if (loading || !currentUser) {
    return <div>Loading user data...</div>;
  }

  // Redirect to auth page if no user is logged in
  if (!currentUser) {
    return <Navigate to="/auth" replace />;
  }
  if (!allowedRoles.includes(currentUser.role)) {
    return <Navigate to="/unauthorized" replace />; // Redirect to an unauthorized page
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
