import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { UilBars } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";

export const pages = [
  { label: "Home", path: "#" }, // Dummy path for scrolling
  { label: "Products", path: "products" },
  { label: "Pricing", path: "pricing" },
  { label: "API Connect", path: "api" },
];

interface NavbarProps {
  onNavigate: (sectionId: string) => void;
}

const Navbar: React.FC<NavbarProps> = ({ onNavigate }) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLinkClick = (path: string) => {
    if (path === "#") {
      // Do nothing or handle special cases if necessary
    } else {
      onNavigate(path);
    }
    handleCloseNavMenu();
  };

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: "none", // Removes the shadow
        backgroundColor: "transparent", // Sets the background to transparent
      }}
    >
      {/* <Container maxWidth="lg"> */}
      <Toolbar disableGutters>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "none" },
            alignItems: "center",
          }}
        >
          <IconButton
            size="large"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            className="text-black"
          >
            <UilBars />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            <MenuItem>
              <Link
                to={"/register"}
                className="text-black fs-16"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Register
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to={"/auth"}
                className="text-black fs-16"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Login
              </Link>
            </MenuItem>
            {pages.map((page) => (
              <MenuItem
                key={page.path}
                onClick={() => handleLinkClick(page.path)}
              >
                <Link
                  key={page.path}
                  to={page.path}
                  className="text-black fs-16"
                  style={{ textDecoration: "none", color: "inherit" }}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default link behavior
                    handleLinkClick(page.path);
                  }}
                >
                  {page.label}
                </Link>
              </MenuItem>
            ))}
            <MenuItem>
              <Link
                to={"/faq"}
                className="text-black fs-16"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                FAQ
              </Link>
            </MenuItem>
          </Menu>
        </Box>

        {/* Render the logo for mobile */}
        <img
          src="../assets/images/nufiLogo.png"
          alt="Logo Small"
          className="logo logo-sm d-lg-none"
          style={{ width: "50px" }}
        />

        {/* Desktop menu and buttons */}
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Logo */}
          <img
            src="../assets/images/dark.png"
            alt="Logo Large"
            style={{ height: "70px" }}
          />

          {/* Menu Links */}
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            {pages.map((page) => (
              <Link
                key={page.path}
                className="nxl-link text-black fs-16"
                to={page.path}
                style={{ textDecoration: "none" }}
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  handleLinkClick(page.path);
                }}
              >
                {page.label}
              </Link>
            ))}
            <Link
              className="nxl-link text-black fs-16"
              to={"/faq"}
              style={{ textDecoration: "none" }}
            >
              FAQ
            </Link>
          </Box>

          {/* Action Button */}
          <div className="d-flex gap-3">
            <button
              className="btn btn-primary"
              onClick={() => window.open("/register", "_blank")}
            >
              Try for free
            </button>
            <button
              className="btn btn-light login-btn"
              onClick={() => window.open("/auth", "_blank")}
            >
              Login
            </button>
          </div>
        </Box>
      </Toolbar>
      {/* </Container> */}
    </AppBar>
  );
};

export default Navbar;
