import { Country } from "../models/Coverage";
import axiosInstance from "./axiosInstance";

const getCountries = async () => {
  const response = await axiosInstance.get(`country/all`);
  return response.data;
};

const createCountry = async (data: Partial<Country>) => {
  const response = await axiosInstance.post("country/create", data);
  return response.data;
};

const createPricing = async (data: any) => {
  const response = await axiosInstance.post("operator/create", data);
  return response.data;
};

const createNewPricing = async (data: any) => {
  const response = await axiosInstance.post("task-sms/add/price", data);
  return response.data;
};

const editPricing = async (operator_code: string, data: any) => {
  const response = await axiosInstance.patch(
    `operator/update/${operator_code}`,
    data
  );
  return response.data;
};

const editNewPricing = async (MCC: string, data: any) => {
  const response = await axiosInstance.patch(
    `task-sms/update/price/${MCC}`,
    data
  );
  return response.data;
};

const getPricings = async () => {
  const response = await axiosInstance.get(`operator/all`);
  return response.data;
};

export const pricingService = {
  createCountry,
  getCountries,
  createPricing,
  getPricings,
  editPricing,
  createNewPricing,
  editNewPricing,
};
