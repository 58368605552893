import React, { useEffect, useState } from "react";
import { UilSave } from "@iconscout/react-unicons";
import { useFormik } from "formik";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { pricingService } from "../../services/pricingService";
import toast from "react-hot-toast";
import { pricingValidationSchema } from "../../validation/FormikValues";
import countryList from "react-select-country-list";
import Select from "react-select";

type Props = {};

const AddPricing = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const handleAddPricing = async (values: any) => {
    try {
      setLoading(true);
      const payload = {
        Country: values.Country,
        iso2: values.iso2,
        PhoneCode: values.PhoneCode,
        PricePartner: values.PricePartner,
        Price: values.Price,
        MNC: values.MNC,
        MCC: Number(values.MCC),
      };
      const response = await pricingService.createNewPricing(payload);
      if (response.status === -1) {
        toast.error(response.message);
        return;
      }

      toast.success("Pricing added successfully!");
      formik.resetForm();
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      Country: "",
      MNC: "",
      MCC: "",
      PhoneCode: "",
      iso2: "",
      PricePartner: 0,
      Price: 0,
    },
    validationSchema: pricingValidationSchema,
    onSubmit: handleAddPricing,
  });

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <div className="d-flex flex-column">
                    <h5 className="m-b-10 border-0 mb-1">Add Pricing</h5>
                    <span className="fs-12 fw-normal text-muted text-truncate-1-line">
                      Please fill in the information carefully
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card stretch stretch-full">
                    <div className="card-body">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                          <div className="mb-3 col-md-4">
                            <label htmlFor="Country">Country</label>
                            <Select
                              options={countryList().getData()} // Fetch country options
                              value={countryList()
                                .getData()
                                .find(
                                  (option: { value: string; label: string }) =>
                                    option.label === formik.values.Country
                                )}
                              onChange={(option) => {
                                formik.setFieldValue("Country", option.label); // Set only the value
                              }}
                              onBlur={formik.handleBlur}
                              name="Country"
                            />
                            {formik.touched.Country && formik.errors.Country ? (
                              <div className="text-danger">
                                {formik.errors.Country}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="iso2">Country ISO2</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Country ISO2"
                              id="iso2"
                              {...formik.getFieldProps("iso2")}
                            />
                            {formik.touched.iso2 && formik.errors.iso2 ? (
                              <div className="text-danger">
                                {formik.errors.iso2}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="PhoneCode">Phone Code</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Phone Code"
                              id="PhoneCode"
                              {...formik.getFieldProps("PhoneCode")}
                            />
                            {formik.touched.PhoneCode &&
                            formik.errors.PhoneCode ? (
                              <div className="text-danger">
                                {formik.errors.PhoneCode}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="PricePartner">
                              Pricing Partner (EUR)
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Pricing Partner"
                              id="PricePartner"
                              {...formik.getFieldProps("PricePartner")}
                            />
                            {formik.touched.PricePartner &&
                            formik.errors.PricePartner ? (
                              <div className="text-danger">
                                {formik.errors.PricePartner}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="Price">Price (EUR)</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Price"
                              id="Price"
                              {...formik.getFieldProps("Price")}
                            />
                            {formik.touched.Price && formik.errors.Price ? (
                              <div className="text-danger">
                                {formik.errors.Price}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="MCC">MCC</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="MCC"
                              id="MCC"
                              {...formik.getFieldProps("MCC")}
                            />
                          </div>
                          <div className="mb-3 col-md-4">
                            <label htmlFor="MNC">MNC</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="MNC"
                              id="MNC"
                              {...formik.getFieldProps("MNC")}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            type="submit"
                            disabled={loading || !formik.isValid}
                            className="btn btn-primary"
                          >
                            {loading && <span className="loader"></span>}
                            <UilSave /> <span className="ms-1">Save</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default AddPricing;
