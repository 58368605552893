import React from "react";
import "./LoaderOverlay.css"; // Create and import this CSS file

type LoaderProps = {
  isLoading: boolean;
};

const LoaderOverlay = ({ isLoading }: LoaderProps) => {
  if (!isLoading) return null;

  return (
    <div className="overlay flex-column gap-3">
      <span className="loader_t"></span>
      <h3 className="text-primary">Loading...</h3>
    </div>
  );
};

export default LoaderOverlay;
