import React, { useCallback, useEffect, useState } from "react";
import {
  UilAngleLeftB,
  UilPlus,
  UilEllipsisH,
  UilEditAlt,
  UilTrashAlt,
  UilUser,
  UilEnvelope,
  UilPhoneAlt,
  UilSearch,
} from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { AnyAction } from "@reduxjs/toolkit";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import Pagination from "@mui/material/Pagination";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import { ContactModel } from "../../models/Contacts";
import {
  deleteContact,
  editContact,
  getAllContacts,
  getAllWhatsappContacts,
} from "../../redux/slices/contactSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { useLocation } from "react-router-dom";
import ContactExport from "../../components/ContactExport";

type Props = {};

const Contacts = (props: Props) => {
  const [selectedFilter, setSelectedFilter] = useState(true);
  const [selectedContact, setSelectedContact] = useState<ContactModel | null>(
    null
  );
  const [editedContact, setEDitedContact] = useState<ContactModel | null>(null);
  const [isloading, setIsloading] = React.useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  const contacts = useSelector((state: RootState) => state.contacts.contacts);
  const whatsappContacts = useSelector(
    (state: RootState) => state.contacts.whatsappContacts
  );
  const [displayedContacts, setDisplayedContacts] = useState(contacts);

  const handleDelete = () => {
    if (!selectedContact) return;
    setIsloading(true);
    try {
      dispatch(
        deleteContact(selectedContact.contact_code) as unknown as AnyAction
      )
        .unwrap()
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }
          toast.success(res.message);
        })
        .catch((res: any) => toast.error(res.message))
        .finally(() => setIsloading(false));
    } catch (error) {
      toast.error("An error occured during contact delete");
    }
  };
  const handleEdit = () => {
    if (!editedContact) return;
    setIsloading(true);
    try {
      dispatch(
        editContact({
          contacts: editedContact,
          code: editedContact.contact_code,
        }) as unknown as AnyAction
      )
        .unwrap()
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }
          toast.success(res.message);
        })
        .catch((res: any) => {
          toast.error(res.message);
        })
        .finally(() => setIsloading(false));
    } catch (error) {
      toast.error("An error occured during contact edit");
    }
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editedContact) {
      setEDitedContact({
        ...editedContact,
        [e.target.name]: e.target.value,
      });
    }
  };

  const fetchContacts = useCallback(
    async (action: any) => {
      try {
        await dispatch(action);
      } catch (err) {
        toast.error("Failed to fetch contacts: An error occurred.");
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const action = selectedFilter ? getAllWhatsappContacts() : getAllContacts();

    fetchContacts(action);
  }, [fetchContacts, location.search, selectedFilter]);

  useEffect(() => {
    // Update displayedContacts when filter or data changes
    if (selectedFilter) {
      setDisplayedContacts(whatsappContacts);
    } else {
      setDisplayedContacts(contacts);
    }
  }, [selectedFilter, contacts, whatsappContacts]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFilter(event.target.checked);
  };

  // Filter contacts based on search query
  const filteredContacts = displayedContacts.filter(
    (contact) =>
      contact.firstname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.lastname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.phone.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate the number of pages for filtered contacts
  const totalPages = Math.ceil(filteredContacts.length / rowsPerPage);

  // Slice contacts for the current page
  const currentContacts = filteredContacts.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const handleChangePage = (event: any, value: number) => {
    setPage(value);
  };

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
    setPage(1); // Reset to first page on search
  };

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <div className="d-flex gap-2 align-items-center">
                    <h5 className="m-b-10 border-0 p-0">App Contacts</h5>
                    <Switch
                      checked={selectedFilter}
                      onChange={handleFilterChange}
                      inputProps={{
                        "aria-label":
                          "Toggle between App and WhatsApp contacts",
                      }}
                    />
                    <h5 className="m-b-10 border-0 p-0">WhatsApp Contacts</h5>
                  </div>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>Back</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                    <div className="d-flex align-items-center border rounded p-2">
                      <input
                        type="text"
                        placeholder="Search Contacts"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="border-0 border-bottom p-0"
                      />
                      <UilSearch />
                    </div>
                    <ContactExport contacts={displayedContacts} />
                    <a className="btn btn-primary" href="contacts-create">
                      <UilPlus />
                      <span>Create Contact</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-content">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card stretch stretch-full">
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table table-hover" id="customerList">
                          <thead>
                            <tr>
                              <th className="wd-30">
                                <div className="btn-group mb-1">
                                  <div className="custom-control custom-checkbox ms-1">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="checkAllCustomer"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="checkAllCustomer"
                                    ></label>
                                  </div>
                                </div>
                              </th>
                              <th>Firstname</th>
                              <th>Lastname</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th className="text-end">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentContacts &&
                              currentContacts.map((contact, index) => (
                                <tr key={index} className="single-item">
                                  <td>
                                    <div className="item-checkbox ms-1">
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input checkbox"
                                          id={contact.phone}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={contact.phone}
                                        ></label>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 text-black">
                                      <div>
                                        <span className="text-truncate-1-line">
                                          {contact.firstname || "---"}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 text-black">
                                      <div>
                                        <span className="text-truncate-1-line">
                                          {contact.lastname || "---"}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 text-black">
                                      {contact.email || "---"}
                                    </div>
                                  </td>
                                  <td>
                                    <a href="tel:">{contact.phone}</a>
                                  </td>
                                  <td>
                                    <div className="hstack gap-2 justify-content-end">
                                      {/* <a
                                        className="avatar-text avatar-md"
                                        href="customers-view.html"
                                      >
                                        <UilEye className="small-svg" />
                                      </a> */}
                                      <div className="dropdown ">
                                        <button
                                          className="avatar-text avatar-md"
                                          data-bs-toggle="dropdown"
                                          data-bs-offset="0,21"
                                        >
                                          <UilEllipsisH className="small-svg" />
                                        </button>
                                        <ul className="dropdown-menu">
                                          <li>
                                            <button
                                              className="dropdown-item border-0"
                                              onClick={() =>
                                                setEDitedContact(contact)
                                              }
                                              data-bs-toggle="modal"
                                              data-bs-target="#editModal"
                                            >
                                              <UilEditAlt className="me-3" />
                                              <span>Edit</span>
                                            </button>
                                          </li>
                                          <li className="dropdown-divider"></li>
                                          <li>
                                            <button
                                              className="dropdown-item border-0"
                                              onClick={() =>
                                                setSelectedContact(contact)
                                              }
                                              data-bs-toggle="modal"
                                              data-bs-target="#deleteModal"
                                            >
                                              <UilTrashAlt className="me-3" />
                                              <span>Delete</span>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {displayedContacts?.length === 0 && (
                          <h5 className="text-center my-5">
                            Your contact list will appear here
                          </h5>
                        )}
                        <Stack
                          spacing={2}
                          sx={{ marginTop: 2, marginBottom: 2 }}
                        >
                          <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handleChangePage}
                            color="primary"
                          />
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/* delete modal  */}
        <div
          className="modal fade"
          id="deleteModal"
          tabIndex={-1}
          aria-labelledby="deleteModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete Contact
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {" "}
                Do you really want to delete the contact{" "}
                {selectedContact?.firstname} {selectedContact?.lastname}-
                {selectedContact?.phone} ?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDelete}
                  disabled={isloading}
                  data-bs-dismiss="modal"
                >
                  {isloading && <span className="loader"></span>}
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*edit modal */}
        <div
          className="modal fade"
          id="editModal"
          tabIndex={-1}
          aria-labelledby="editModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Contact
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form action="">
                  <div className="col-12 mb-4 ">
                    <label htmlFor="firstname" className="fw-semibold">
                      Firstname:
                    </label>
                    <div className="input-group">
                      <div className="input-group-text">
                        <UilUser />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        name="firstname"
                        placeholder="Firstname"
                        value={editedContact?.firstname || ""}
                        onChange={handleEditChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-4 ">
                    <label htmlFor="lastname" className="fw-semibold">
                      Lastname:
                    </label>
                    <div className="input-group">
                      <div className="input-group-text">
                        <UilUser />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        name="lastname"
                        placeholder="Lastname"
                        value={editedContact?.lastname}
                        onChange={handleEditChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-4 ">
                    <label htmlFor="email" className="fw-semibold">
                      Email:
                    </label>
                    <div className="input-group">
                      <div className="input-group-text">
                        <UilEnvelope />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={editedContact?.email}
                        onChange={handleEditChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-4 ">
                    <label htmlFor="phone" className="fw-semibold">
                      Phone:
                    </label>
                    <div className="input-group">
                      <div className="input-group-text">
                        <UilPhoneAlt />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                        value={editedContact?.phone}
                        onChange={handleEditChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={handleEdit}
                  disabled={!editedContact?.phone || isloading}
                >
                  {isloading && <span className="loader"></span>}
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default Contacts;
