import { Package } from "../models/Package";
import axiosInstance from "./axiosInstance";

const getPackages = async () => {
  const response = await axiosInstance.get(`package/all`);
  return response.data;
};

const createPackage = async (data: Package) => {
  const response = await axiosInstance.post("package/create", data);
  return response.data;
};

const subscribePackage = async (data: {
  userId: number;
  packageId: number;
}) => {
  const response = await axiosInstance.post("package/subscribe", data);
  return response.data;
};

const unSubscribePackage = async (data: {
  userId: number;
  packageId: number;
}) => {
  const response = await axiosInstance.post("package/unsubscribe", data);
  return response.data;
};

const editPackage = async (package_code: string, data: Partial<Package>) => {
  const response = await axiosInstance.put(`package/${package_code}`, data);
  return response.data;
};

export const packageService = {
  createPackage,
  editPackage,
  getPackages,
  subscribePackage,
  unSubscribePackage,
};
